








import { Component, Vue } from "vue-property-decorator";
import Categoria from "@/components/mascotas/CategoriaM.vue";

@Component({
  components: {
    Categoria
  }
})
export default class CategoriaView extends Vue {
  private mounted() {
    document.title = "Mascotas - Municipalidad de Arica";

    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://muniarica.cl/mascotas";
    document.head.appendChild(canonicalLink);
  }
}
