








import { Component, Vue } from "vue-property-decorator";
import Publicacion from "@/components/Actividades/Publicacion.vue";

@Component({
  components: {
    Publicacion
  }
})
export default class PublicacionView extends Vue {}
